<template>
	<div class="box0">
		
		<div class="top0 flex-space-between">
			
			<div class="flex-row-center">
				<div class="box-logo flex-row-center">
					<el-image :src="logo"></el-image>
				</div>
				<div v-if="!is_mobile" class="select-list flex-row-center">
					<div v-for="(item,index) in list" :key="index">
					    <div :class=" index==selectIndex ? 'select-item1':'select-item0'"
						@click="onSelectTop(index)">
							{{item.title}}
						</div>
					</div>
				</div>
			</div>
			<div>
				
			</div>
			<div v-if="is_mobile" class="box-me1" @click="onClickMe">
				联系我
			</div>
			<div v-else class="box-me">
				欢迎联系我
			</div>
		</div>
		
		<div v-if="is_mobile" class="mobile-box">
			<div class="select-list flex-row-center">
				<div v-for="(item,index) in list" :key="index">
				    <div :class=" index==selectIndex ? 'select-item1':'select-item0'"
					@click="onSelectTop(index)">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
		
		<div class="box-content">
			<div class="caseDiv">
				
				<div class="container-fluid">
					
					<div v-for="(item,index) in list[selectIndex].list" :key="index">
						<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 case wow fadeInRight  animated"
						
						    data-wow-delay="100ms" 
						    style="visibility: visible; animation-delay: 100ms; animation-name: fadeInRight;">
						    
							<div class="case-img">
						        <a href="javascript:void(0)">
						            <div class="caseBlack">
						                <img :src="item.code" />
						                <div class="caseTitle">
						                    <text>{{item.title}}</text>
											<img class="nav_right" src="@/assets/img/index/nav_right.png">
						                </div>
						            </div>
						        </a>
						        <img class="caseIMG" :src="item.url">
						    </div>
						    <p class="case-name">{{item.title}}</p>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
		<div class="box-bottom flex-center">
			提供各类开发和一站式搭建服务
		</div>
		
		<el-dialog title="欢迎咨询" v-model="show_me_dailog" width="80%" center>
		    <div class="flex-col-center">
				
				<el-image :src="code" fit="fill"
				style="width:100%;height:100%;max-width: 300px;"></el-image>
				<div @click="onCopy">长按识别二维码加我</div>
				
				<div class="box-user" @click="onCopy">微信号：yoonabs(复制加我)</div>
				
			</div>
			
		</el-dialog>
		
	</div>
</template>

<script src="./index.js">
</script>

<style scoped>
@import url("./index.css");
</style>