/*
图片尺寸规范:
banner : 440x228
二维码 : 430x430
*/

export const productList = [
	{
		title:"微信公众号",
		list:[
			{
				title:"恋爱盲盒",
				url:require("@/assets/img/banner/manghe.png"),
				code:require("@/assets/img/code/manghe.png")
			},
			{
				title:"浅拍",
				url:require("@/assets/img/banner/qianpai.png"),
				code:require("@/assets/img/code/qianpai.png")
			}
		]
	},
	{
		title:"微信小程序",
		list:[
			{
				title:"遥望小空投",
				url:require("@/assets/img/banner/ywxkt.png"),
				code:require("@/assets/img/code/ywxkt.jpg")
			},
			{
				title:"PDF云大师",
				url:require("@/assets/img/banner/pdf.png"),
				code:require("@/assets/img/code/pdf.jpg")
			},
			{
				title:"零距CP",
				url:require("@/assets/img/banner/ljcp.png"),
				code:require("@/assets/img/code/ljcp.jpg")
			},
			{
				title:"婚恋平台",
				url:require("@/assets/img/banner/marry.png"),
				code:require("@/assets/img/code/marry.jpg")
			}
		]
	},
	{
		title:"小游戏",
		list:[
			{
				// https://games.jl70.com/kdwll/index.html
				title:"快到碗里来",
				url:require("@/assets/img/banner/kdwll.png"),
				code:require("@/assets/img/code/kdwll.png")
			},
			{
				// https://games.jl70.com/jsfk/index.html
				title:"解锁方块",
				url:require("@/assets/img/banner/jsfk.png"),
				code:require("@/assets/img/code/jsfk.png"),
			},
			{
				// https://games.jl70.com/archery/index.html
				title:"弓箭手大作战",
				url:require("@/assets/img/banner/archery.png"),
				code:require("@/assets/img/code/archery.png"),
			},
			{
				// https://games.jl70.com/rwllk/index.html
				title:"让我连连看",
				url:require("@/assets/img/banner/rwllk.png"),
				code:require("@/assets/img/code/rwllk.png"),
			},
			{
				// https://games.jl70.com/swmg/index.html
				title:"三维磁力迷宫",
				url:require("@/assets/img/banner/swmg.png"),
				code:require("@/assets/img/code/swmg.png"),
			},
			{
				// https://games.jl70.com/rh/index.html
				title:"绕环",
				url:require("@/assets/img/banner/rh.png"),
				code:require("@/assets/img/code/rh.png"),
			},
			{
				// https://games.jl70.com/dcqzy/index.html
				title:"倒车请注意",
				url:require("@/assets/img/banner/dcqzy.png"),
				code:require("@/assets/img/code/dcqzy.png"),
			},
			{
				// https://games.jl70.com/chqqb/index.html
				title:"彩虹七巧板",
				url:require("@/assets/img/banner/chqqb.png"),
				code:require("@/assets/img/code/chqqb.png"),
			},
			
			{
				// https://games.jl70.com/hcb1024/index.html
				title:"1024合成版",
				url:require("@/assets/img/banner/1024.png"),
				code:require("@/assets/img/code/1024.png"),
			},
			{
				// https://games.jl70.com/ljds/index.html
				title:"连接大师",
				url:require("@/assets/img/banner/ljds.png"),
				code:require("@/assets/img/code/ljds.png")
			},
			{
				// https://games.jl70.com/qqzd/index.html
				title:"球球找洞",
				url:require("@/assets/img/banner/qqzd.png"),
				code:require("@/assets/img/code/qqzd.png")
			},
			{
				// https://games.jl70.com/szxxx/index.html
				title:"数字消消消",
				url:require("@/assets/img/banner/szxxx.png"),
				code:require("@/assets/img/code/szxxx.png")
			},
			{
				// https://games.jl70.com/wzwzl/index.html
				title:"我走位贼溜",
				url:require("@/assets/img/banner/wzwzl.png"),
				code:require("@/assets/img/code/wzwzl.png")
			},
			{
				// https://games.jl70.com/xgsj/index.html
				title:"斜杠三角",
				url:require("@/assets/img/banner/xgsj.png"),
				code:require("@/assets/img/code/xgsj.png")
			}
		]
	}
];
