import {productList} from "@/config/vars"

export default {
	
	data(){
		return{
			list:productList,
			
			logo:require('@/assets/img/logo.png'),
			code:require('@/assets/img/wx.png'),
			
			wx:"yukeeok",
			is_mobile:false,
			show_me_dailog:false,
			
			selectIndex:0,
			
		}
	},
	
	init(){
		console.log("index init ");
	},
	
	created(){
		
		this.is_mobile = this.isMobile();
		
		let url = location.href.split("#/")[0];
		let index = this.getUrlParam(url,"i");
		if(index){
			this.selectIndex = index;
		}
		
		console.log("index created ");	
	},
	
	
	methods:{
		
		getUrlParam(url, name){
		    let myJson = {};
		    let hashes = url.slice(url.indexOf('?') + 1).split('&');
		    for (let i = 0; i < hashes.length; i++) {
		        let hash = hashes[i].split('=');
		        myJson[hash[0]] = hash[1];
		    }
		    return myJson[name];
		},
		
		isMobile(){
			if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
			    return true;
			}
			return false;
		},
		
		onSelectItem(){
			console.log("做了啥");
			this.num += 1;
		},
		
		onSelectTop(index){
		    this.selectIndex = index;	
		},
		
		// 
		onClickMe(){
			this.show_me_dailog = true;
		},
		
		onCopy(){
			this.$copyText(this.wx).then(() => {
			    this.$message.success('复制成功！')
			}, () => {
			    this.$message.error('复制失败！')
			});
		}
		
	}
	
	
}